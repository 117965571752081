.about__section-item {
  column-gap: 3rem;
}

.about__section-item p i {
  color: #f9a826;
  font-size: 1.1rem;
}

.contact__btn {
  background: #000000 !important;
  color: #fff !important;
  border: none;
  outline: none;
}

.contact__btn a {
  text-decoration: none;
  font-weight: 600;
  color: #fff;
}

.contact__btn:hover {
  background: #666666 !important; 
}

@media only screen and (max-width: 992px) {
  .about__section-content h2 {
    font-size: 1.5rem;
  }

  .about__section-item {
    column-gap: 3rem;
  }

  .about__section-item p {
    font-size: 0.6rem;
    margin-bottom: 0;
  }

  .section__description i {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .about__img {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .about__section {
    margin-top: 280px;
  }
}

/* #000d6b */