.contact-icon-container {
    position: relative;
    display: inline-block;
  }
  
  .contact-icon-container .message {
    position: absolute;
    top: 50%;
    left: -120px;
    transform: translateY(-50%);
    background-color: rgba(255, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
  
  .contact-icon-container .message p {
    color: white;
    margin: 0;
  }
  
  .contact-icon-container .message.open {
    opacity: 1;
  }
  
  .contact-icon-container:hover .message {
    opacity: 1;
  }
  
  .contact-icon {
    cursor: pointer;
  }
  
  .contact-icon:hover {
    transform: scale(1.1);
    transition: transform 1s ease;
  }

  .message {
    color: red;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .message.show {
    opacity: 1;
  }
  
  .message.hide {
    opacity: 0;
  }
  
  .form-container {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }
  
  .form-container.expand {
    height: auto;
  }
  
  .form-container.collapse {
    height: 0;
  }
  
  